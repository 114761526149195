<template>
  <div>
   <span v-if="showEditButton">
                <vs-icon @click="goToEdit();"  icon="edit" size="small" class="float-right"></vs-icon>
            </span>


    <div>
      <label class="vs-input--label w-full">What are you selling? <span class="mid-blue">*</span></label>
      <p>{{payload.product}}</p>

    </div>

    <div class="flex mt-4">
      <div class="w-full mr-4">
        <label class="vs-input--label w-full">Products/ Services Description <span class="mid-blue">*</span></label>
         <p>{{payload.productDescription}}</p>

      </div>
      <div class="w-full">
        <label class="vs-input--label w-full">Business Summary <span class="mid-blue">*</span></label>
         <p>{{payload.businessSummary}}</p>

      </div>
    </div>

    <div class="flex mt-4">
      <div class="w-full mr-4">
        <label class="vs-input--label w-full">Annual turnover (last 12 months) <span class="mid-blue">*</span></label>
           <p>{{payload.annualTurnoverLast12Month}}</p>

      </div>

      <div class="w-full">
        <label class="vs-input--label w-full">Forecast turnover <span class="mid-blue">*</span></label>
            <p>{{payload.forecastTurnover}}</p>

      </div>
    </div>

    <div class="flex mt-4">
      <div class="w-full mr-4">
        <label class="vs-input--label w-full">Annual profit (last 12 months) <span class="mid-blue">*</span></label>
          <p>{{payload.profitLast12Month}}</p>

      </div>

      <div class="w-full">
        <label class="vs-input--label w-full">Average transaction size <span class="mid-blue">*</span></label>
         <p>{{payload.averageTransactionSize}}</p>

      </div>
    </div>

    <div class="flex mt-4">
      <div class="w-full mr-4">
        <label class="vs-input--label w-full">Number of transaction per month <span class="mid-blue">*</span></label>
           <p>{{payload.transactionsPerMonth}}</p>

      </div>

      <div class="w-full">
        <label class="vs-input--label w-full">Average Debtors <span class="mid-blue">*</span></label>
          <p>{{payload.averageDebtors}}</p>

      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "BusinessProfile",
  props:['businessProfile','showEditButton', 'userId'],
  // props: {
  //   businessProfile: { type: Object }
  // },
  data() {
    return {
      payload: {
        isComplete: false,
        product: "",
        productDescription: "",
        businessSummary: "",
        annualTurnoverLast12Month: 0.00,
        forecastTurnover: 0.00,
        profitLast12Month: 0.00,
        averageTransactionSize: 0,
        transactionsPerMonth: 0,
        averageDebtors: 0
      }
    }
  },
   methods:{
    goToEdit(){
            this.$router.push({name:"application",params:{userId:this.userId,card:"businessProfile"}})
        }

  },
  created() {
    this.payload = this.businessProfile;
  },
}
</script>
