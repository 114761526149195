<template>
    <div>
        <!-- {{bankAccount}} -->
        <div></div>

        <template v-for="(bank,key) in bankAccount"> 
          <!-- <template> -->
            <vs-col :key="key"  class="mb-4">
            <div>
                <label class="vs-input--label"> <vs-icon icon="business" size="small"></vs-icon>  Account  </label>
                <hr>
            </div>
            <vs-col vs-w="5" class=" mt-3">
                <div class="w-full">
                    <label class="vs-input--label w-full">Account name *</label>
                      <p>{{bank.accountName}}</p>
            </div>
            </vs-col>
            {{showEditButton}}
             <vs-col v-if="showEditButton" vs-w="7" class=" mt-3">
                <div class="w-full">
                    <span>
                        <vs-icon @click="goToEdit()"  icon="edit" size="small" class="float-right"></vs-icon>
                    </span>
                </div>
            </vs-col>

            <vs-col vs-w="8" class=" mt-3">
                <vs-row class="flex">

                    <vs-col vs-w="6" class="w-full">
                        <label class="vs-input--label w-full">BSB *</label>
                         <p>{{bank.bsb}}</p>
                    </vs-col>

                    <vs-col vs-w="6" class="w-full">
                        <label class="vs-input--label w-full">Account no. *</label>
                        <p>{{bank.accountNumber}}</p>    
                    </vs-col>
                </vs-row>
                

            </vs-col>

            <vs-col vs-w="6" class="w-full">
                <label class="vs-input--label w-full">Bank statement *</label>
                
            </vs-col>

            <vs-col vs-w="6" class="w-full">
            <vs-checkbox v-model="bankAccount.isUsedForSettlement" :disabled="true" >This account will be used for settlement</vs-checkbox>
            <vs-checkbox v-model="bankAccount.isUsedForBilling" :disabled="true" >This account will be used for billing</vs-checkbox>
            </vs-col>
         </vs-col>
        </template>

       
    </div>
</template>

<script>

export default {
   // name:"BankAccount",
    props:['bankAccount','userId','showEditButton'],
    methods:{
        goToEdit(){
            this.$router.push({name:"application",params:{userId:this.userId,card:"bankaccount"}})
        }
    },
    mounted(){
        
    }

}
</script>
