<template>
  <div>

    <template  v-for="(owner,key) in beneficiaryOwner">
      <div :key="key" class="flex">
        <div class="w-full">
          <div>Beneficial owner {{key+1}}
            <span v-if="showEditButton">
                <vs-icon @click="goToEdit()"  icon="delete_outline" size="small" class="float-right"></vs-icon>
            </span>
          </div>
          <vs-row  class="mb-4">
          <vs-col class="pr-6" vs-xs="12">
            <div class="flex">
              <div class="w-full">
                 <label class="vs-input--label">First Name</label>
               <p>{{owner.firstName}}</p>
              </div>

              <div class="w-full">
                    <label class="vs-input--label">Middle Name</label>
              <p>{{owner.middleName}}</p>
              </div>

            </div>
             <label class="vs-input--label">Last Name</label>
            <p>{{owner.lastName}}</p>
            <div class=" flex">
              <div class="w-full">
                  <label class="vs-input--label">Mobile Phone Number*</label>
                <p>{{owner.mobileNumber}}</p>
              </div>
              <div class="w-full">
                  <label class="vs-input--label">Beneficiary Email*</label>
                <p>{{owner.email}}</p>

              </div>
            </div>
          </vs-col>
        </vs-row>
        </div>
        <div>

      </div>
      </div>
    </template>

  </div>
</template>

<script>

export default {
  name: "BeneficiaryOwner",
  props:['beneficiaryOwner','userId','showEditButton'],
  methods:{
    goToEdit(){
            this.$router.push({name:"application",params:{userId:this.userId,card:"businessInfo"}})
        }

  }
}
</script>