<template>
  <div>
    <vs-col   class="pr-6" vs-xs="12">
            <span v-if="showEditButton">
                <vs-icon @click="goToEdit()"  icon="edit" size="small" class="float-right"></vs-icon>
            </span> 

      <div class="flex">
        <div class="w-full">
              <label class="vs-input--label">First Name*</label>
              <p>{{primaryContactPerson.firstName}}</p>
              
            </div>
            <div class="w-full">
               <label class="vs-input--label">Middle Name*</label>
              <p>{{primaryContactPerson.middleName}}</p>

            
            </div>
      </div>
       <label class="vs-input--label">Last Name*</label>
              <p>{{primaryContactPerson.lastName}}</p>
      <div class=" flex">
        <div class="w-full">
         <label class="vs-input--label  w-full">Mobile Phone Number*</label>
              <p>{{primaryContactPerson.mobileNumber}}</p>
        </div>
        <div class="w-full">
        
              <label class="vs-input--label">Email Address*</label>
              <p>{{primaryContactPerson.email}}</p>
        </div>
      </div>
    </vs-col>
  </div>
</template>

<script>
export default {
  name: "PrimaryContactPerson",
  props:['primaryContactPerson','userId','showEditButton'],
  methods:{
    goToEdit(){
            this.$router.push({name:"application",params:{userId:this.userId,card:"businessInfo"}})
        }

  }
}
</script>
