<template>
  <div>
    <vs-row>
      <vs-col vs-w="12">
        <h5>Payment options</h5>
        <hr />
      </vs-col>

      <vs-col vs-w="12">
        <vs-row>
          <vs-col class="lg:mr-5 w-full" vs-w="12">
            <span v-if="showEditButton">
              <vs-icon
                @click="goToEdit()"
                icon="edit"
                size="small"
                class="float-right"
              ></vs-icon>
            </span>
          </vs-col>
          <vs-col
            class="lg:mr-5 w-auto"
            v-if="product.productOption.includes('pay-now')"
          >
            <div class="radio-card my-5 p-4">
              <vs-row>
                <vs-col
                  vs-w="10"
                  vs-sm="10"
                  vs-lg="10"
                  vs-xs="10"
                  class="radio-label"
                >
                  Pay Now
                </vs-col>
              </vs-row>
              <vs-row>
                <vs-col vs-w="12" vs-sm="12" vs-lg="12" vs-xs="12">
                  <span class="radio-info">
                    Customer pays full amount immediately
                  </span>
                </vs-col>
              </vs-row>
            </div>
          </vs-col>

          <vs-col
            v-if="product.productOption.includes('pay-later')"
            class="lg:mr-5 w-auto"
          >
            <div class="radio-card my-5 p-4">
              <vs-row>
                <vs-col
                  vs-w="10"
                  vs-sm="10"
                  vs-lg="10"
                  vs-xs="10"
                  class="radio-label"
                >
                  Pay Later
                </vs-col>
              </vs-row>
              <vs-row>
                <vs-col vs-w="12" vs-sm="12" vs-lg="12" vs-xs="12">
                  <span class="radio-info">
                    Customer pays over multiple payments on a payment plan
                  </span>
                </vs-col>
              </vs-row>
            </div>
          </vs-col>
        </vs-row>
      </vs-col>
    </vs-row>

    <vs-row>
      <vs-col vs-w="12">
        <h5>Pay Now payment methods</h5>
        <hr />
      </vs-col>
      <vs-col class="lg:mr-5 w-full" vs-w="12">
        <span v-if="showEditButton">
          <vs-icon
            @click="goToEdit()"
            icon="edit"
            size="small"
            class="float-right"
          ></vs-icon>
        </span>
      </vs-col>
      <vs-col vs-w="12">
        <vs-row>
          <p>
            Select the payment methods you’d like to enable on your account.
          </p>
        </vs-row>
        <vs-row>
          <vs-col
            v-for="(paynowPlan, key) in product.payNowPaymentMethods"
            :key="key"
            class="lg:mr-5 w-auto"
          >
            <div class="radio-card my-5 p-4">
              <vs-row>
                <vs-col
                  vs-w="10"
                  vs-sm="10"
                  vs-lg="10"
                  vs-xs="10"
                  class="radio-label"
                >
                  {{ paynowPlan.name }}
                </vs-col>
              </vs-row>
              <vs-row>
                <vs-col vs-w="12" vs-sm="12" vs-lg="12" vs-xs="12">
                  <div>
                    <label class="w-full">
                      Merchant service fee: {{ paynowPlan.msf }}%</label
                    >
                    <label class="w-full">
                      Card surcharge : {{ paynowPlan.surchanrge }}</label
                    >
                  </div>
                </vs-col>
              </vs-row>
            </div>
          </vs-col>
        </vs-row>
      </vs-col>
    </vs-row>

    <vs-row>
      <vs-col vs-w="12">
        <h5>Pay Later products</h5>
        <hr />
      </vs-col>
      <vs-col class="lg:mr-5 w-full" vs-w="12">
        <span v-if="showEditButton">
          <vs-icon
            @click="goToEdit()"
            icon="edit"
            size="small"
            class="float-right"
          ></vs-icon>
        </span>
      </vs-col>
      <vs-col vs-w="12">
        <vs-row>
          <p>Select the Pay Later products you’d like to enable.</p>
        </vs-row>
        <vs-row>
          <vs-col
            v-for="(payLaterPlan, key) in product.payLaterProducts"
            :key="key"
            class="lg:mr-5 w-auto"
          >
            <div class="radio-card my-5 p-4">
              <vs-row>
                <vs-col
                  vs-w="10"
                  vs-sm="10"
                  vs-lg="10"
                  vs-xs="10"
                  class="radio-label"
                >
                  {{ payLaterPlan.name }}
                </vs-col>
              </vs-row>
              <vs-row>
                <vs-col vs-w="12" vs-sm="12" vs-lg="12" vs-xs="12">
                  <div>
                    <label class="w-full"> MSF to customer (MSF-C)</label>
                    <p>{{ payLaterPlan.description }}%</p>
                  </div>
                </vs-col>
              </vs-row>
            </div>
          </vs-col>
        </vs-row>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
export default {
  name: "ProductSelection",
  props: {
    product: { type: Object },
    userId: { type: String },
    showEditButton: { type: Boolean },
  },
  methods: {
    goToEdit() {
      this.$router.push({
        name: "application",
        params: { userId: this.userId, card: "productSelection" },
      });
    },
  },
};
</script>
