<template>
  <div>
    <template v-for="(signature,key) in authorisedSignature">

          

      <vs-row :key="key"  class="mb-4">
        <div>Authorised signatory {{key+1}}  
          
            <span v-if="showEditButton">
                <vs-icon @click="goToEdit()"  icon="edit" size="small" class="float-right"></vs-icon>
            </span> 
            
           </div>
        <vs-col class="pr-6" vs-xs="12">
          <div class=" flex">
            <div class="w-full">
              <label class="vs-input--label">First Name*</label>
              <p>{{signature.firstName}}</p>
              
            </div>
            <div class="w-full">
               <label class="vs-input--label">Middle Name*</label>
              <p>{{signature.middleName}}</p>

            
            </div>
          </div>

            <label class="vs-input--label">Last Name*</label>
              <p>{{signature.lastName}}</p>

          <div class=" flex">
            <div class="w-full">

                <label class="vs-input--label w-full">Mobile Phone Number*</label>
              <p>{{signature.mobileNumber}}</p>

            
            </div>
            <div class="w-full">


              <label class="vs-input--label">Email Address*</label>
              <p>{{signature.email}}</p>


           
            </div>
          </div>
        </vs-col>
     
      </vs-row>
    </template>
  
  </div>
</template>

<script>
export default {
  name: "AuthorisedSignature",
  props:['authorisedSignature','userId','showEditButton'],
  methods:{
    goToEdit(){
            this.$router.push({name:"application",params:{userId:this.userId,card:"businessInfo"}})
        }

  }
}
</script>

