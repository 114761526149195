<template>
  <div>
    <hr>
    <div>
<!--    <span v-if="showEditButton">
        <vs-icon @click="goToEdit()"  icon="edit" size="small" class="float-right"></vs-icon>
    </span> -->

    <div class="flex">
      <div class="w-full">
        <label class="vs-input--label">ABN</label>
        <p>{{payload.abn}}</p>
      </div>
      <div class="w-full">
        <label class="vs-input--label w-full">Company legal name</label>
       <p>{{payload.companyLegalName}}</p>
      </div>
    </div>

     <div class="flex">
      <div class="w-full">
        <label class="vs-input--label">Business type </label>
        <p>{{payload.businessType}}</p>
      </div>
      <div class="w-full">
        <label class="vs-input--label w-full">GST Status </label>
       <p>{{payload.gstStatus}}</p>
      </div>
    </div>

    <div class="flex">
      <div class="w-full">
        <label class="vs-input--label">Registered on  </label>
        <p>{{payload.registeredOn}}</p>
      </div>
      <div class="w-full">
        <label class="vs-input--label w-full">Trading name </label>
        <p>{{payload.tradingName}}</p>
      </div>
    </div>




    <div class="flex">
      <div class="w-full">
        <label class="vs-input--label">Industry</label>
         <p>{{payload.industry}}</p>
      </div>

      <div class="w-full">
        <label class="vs-input--label">Category</label>
       <p>{{payload.category}}</p>
      </div>
    </div>
    <div class="flex">
      <div class="w-full">
        <label class="vs-input--label">Website URL</label>
         <p>{{payload.websiteUrl}}</p>
      </div>
    </div>
    </div>

    <div>


     <div class="flex mt-3">
      <div class="w-full">
        <label class="vs-input--label">Registered address * </label>
         <p>{{payload.registeredAddress.streetAddress}}</p>
      </div>
    </div>

     <div class="flex mt-3">
      <div class="w-full">
        <label class="vs-input--label">Apt, Suite, Unit, Building, Floor </label>
         <p>{{payload.registeredAddress.streetAddress}}</p>
      </div>
    </div>

     <div class="flex mt-3">
      <div class="w-full">
        <label class="vs-input--label">Street address * </label>
         <p>{{payload.registeredAddress.streetAddress}}</p>
      </div>

       <div class="w-full">
        <label class="vs-input--label">Suburb * </label>
         <p>{{payload.registeredAddress.suburb}}</p>
      </div>
    </div>

     <div class="flex mt-3">
      <div class="w-full">
        <label class="vs-input--label">State * </label>
          <p>{{payload.registeredAddress.state}}</p>
      </div>

       <div class="w-full">
        <label class="vs-input--label">Postcode * </label>
       <p>{{payload.registeredAddress.postCode}}</p>
      </div>
    </div>



       <div class="flex mt-3">
      <div class="w-full">
        <label class="vs-input--label"><b>Trading address </b> </label>
      </div>
    </div>

    <div class="flex mt-3">
      <div class="w-full">
        <vs-checkbox v-model="payload.isSameAsRegisteredAddress" :disabled="true" >Same as registered address</vs-checkbox>
      </div>
    </div>

     <div class="flex mt-3">
      <div class="w-full">
        <label class="vs-input--label">Apt, Suite, Unit, Building, Floor </label>
       <p>{{payload.tradingAddress.streetAddress}}</p>
      </div>
    </div>

     <div class="flex mt-3">
      <div class="w-full">
        <label class="vs-input--label">Street address * </label>
      <p>{{payload.tradingAddress.streetAddress}}</p>
      </div>

       <div class="w-full">
        <label class="vs-input--label">Suburb * </label>
       <p>{{payload.tradingAddress.suburb}}</p>
      </div>
    </div>

     <div class="flex mt-3">
      <div class="w-full">
        <label class="vs-input--label">State * </label>
       <p>{{payload.tradingAddress.state}}</p>
      </div>

       <div class="w-full">
        <label class="vs-input--label">Postcode * </label>
        <p>{{payload.tradingAddress.postCode}}</p>
      </div>
    </div>






    </div>


  </div>
</template>

<script>

export default {
  name: "GeneralInformation",
  props:{
    generalInfo:{type:Object},
    userId:String,
    showEditButton:Boolean
  },
  data(){
    return{
      payload:{
        abn: "",
        businessType: "",
        companyLegalName: "",
        gstStatus: "",
        registeredOn: "",
        industry: "",
        category: "",
        websiteUrl: "",
        tradingName: "",
        registeredAddress: {
          searchedText: "",
          streetAddress: "",
          suburb: "",
          state: "",
          postCode: "",
        },
        isSameAsRegisteredAddress: false,
        tradingAddress: {
          searchedText: "",
          streetAddress: "",
          suburb: "",
          state: "",
          postCode: "",
        },
      }
    }
  },
  methods:{
    goToEdit(){
            this.$router.push({name:"application",params:{userId:this.userId,card:"businessInfo"}})
        }

  },
  mounted(){
    this.payload = this.generalInfo;
  }
}
</script>
