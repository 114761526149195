<template>
  <div>
     <vs-button @click="popupActivo=true" color="primary" type="border">Upload File</vs-button>

   <vs-popup class="holamundo"  title="Upload a file" :active.sync="popupActivo">
      <p>Please provide the following details about this beneficial owner.</p>

       <div class="flex">
        <div class="w-full">
          <label class="vs-input--label">Document name / description *</label>
          <vs-input v-model="title"></vs-input>
        </div>
        <div class="w-full">
          <label class="vs-input--label w-full">Upload document *</label>
          <br>
          <input
            type="file"
            class="hidden"
            name="file"
            ref="uploadImgInput"
            @change="updateCurrImg($event)"
            accept=".pdf"
          />
          <vs-button
           v-if="!file"    
              flat
              class="only-border-btn"
              small
              v-round
              @click="uploadImgInput(key)">
              Upload </vs-button>
              <span v-if="file">{{file.name}}</span>
        </div>
      </div>

       <div class="flex">
        <div class="w-full">
            <label class="vs-input--label">Note *</label>
            <vs-textarea v-model="note" placeholder="Please add a note about this document"></vs-textarea>
        </div>
      </div>

      <div class="flex flex-right">
            <vs-button    
            flat
            v-round
            class="border-only-btn mr-2">Delete File </vs-button>

             <vs-button    
            flat
            v-round
            @click="saveFile()"
            class="border-only-btn mr-2">Save File </vs-button>

             <vs-button    
            flat
            v-round
            class="border-only-btn mr-2">Cancel</vs-button>
      </div>



    </vs-popup>

  </div>
</template>

<script>
import {mapActions} from "vuex"

export default {
  name: "SupportingDocument",
  // props: {
  //   businessProfile: { type: Object }
  // },
  data() {
    return {
      popupActivo:false,
      title:"",
      file:"",
      note:""
    }
  },
   methods:{
      ...mapActions("onboardMerchant",['saveSupportingFile']),
      goToEdit(){
        this.$router.push({name:"application",params:{userId:this.userId,card:"businessProfile"}})
      },
      uploadImgInput(key){
          this.$refs.uploadImgInput.click() ;
      },
      updateCurrImg(input,key) {
          let files = input.target.files[0];
          this.file = files;
      },
      async saveFile(){

        const formData = new FormData();

        formData.append("title",this.title);
        formData.append("note", this.note);
        formData.append("supportFile", this.file);

        const payload = {
          data: formData,
          config: {
            header: {
              "Content-Type": "multipart/form-data",
            },
          },
        };
        await this.saveSupportingFile(payload).then((result)=>{
          
        }).catch((err)=>{{}});
      }
  },
  created() {
    this.payload = this.businessProfile;
  },
}
</script>
