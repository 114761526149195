<template>
  <div>
    <router-link v-if="!showEditButton" :to="{name:'application',params:{userId:userId}}">  Back </router-link>
    <h2 class="mt-8 mb-8" >Onboarding Form Review </h2>
    <!-- {{onboardDetail}} -->
    <vs-row id="profile-page" class="mt-5">

        <!-- Business Information -->
        <vx-card class="accordin-card">
          <div class="vx-card__body bsv p-0">
            <vs-collapse accordion >
              <!-- open -->
              <vs-collapse-item open  ref="templateCollapse">
                <div slot="header">
                  <div style="width: 40%;" class="flex flex-row">
                    <feather-icon icon="HomeIcon" svgClasses="w-4 h-4"/>
                    <h3 class="text-left card-title font-normal px-4">
                      Business Information
                    </h3>
                    <div class="ml-auto"></div>
                  </div>
                </div>

                <vs-row>
                  <!-- General Information -->
                  <div class="w-full mb-8">
                    <div class="mb-4 text-black text-2xl font-normal"> <vs-icon icon="business" size="small"></vs-icon> General Information  </div>


                      <GeneralInformation
                      :generalInfo="onboardDetail.businessInfo"
                      :userId="onboardDetail.userId"
                      :showEditButton="showEditButton"
                      />
                  </div>

                  <!-- Beneficiary owners -->
                  <div class="w-full mb-8">
                    <div class="mb-4 text-black text-2xl font-normal"> <vs-icon icon="border_color" size="small"></vs-icon>
                    Beneficial Owners

                    </div>
                    <hr>
                    <BeneficiaryOwner
                    :beneficiaryOwner="onboardDetail.businessInfo.beneficialOwners"
                    :userId="onboardDetail.userId"
                    :showEditButton="showEditButton"
                    />
                  </div>

                  <!-- Authorised signatures -->
                  <div class="w-full mb-8">
                    <div class="mb-4 text-black text-2xl font-normal"> <vs-icon icon="border_color" size="small"></vs-icon> Authorised signatories   </div>
                    <hr>
                    <AuthorisedSignature
                    :authorisedSignature="onboardDetail.businessInfo.authorisedSignatures"
                    :userId="onboardDetail.userId"
                    :showEditButton="showEditButton"
                    />
                  </div>

                  <!-- Primary contact person -->
                  <div class="w-full mb-8">
                    <div class="mb-4 text-black text-2xl font-normal"> <vs-icon icon="border_color" size="small"></vs-icon> Primary contact person  </div>
                     <hr>
                    <PrimaryContactPerson
                    :primaryContactPerson="onboardDetail.businessInfo.primaryContactPerson"
                    :userId="onboardDetail.userId"
                    :showEditButton="showEditButton"
                    />

                  </div>
                </vs-row>
              </vs-collapse-item>
            </vs-collapse>
          </div>
        </vx-card>

        <!-- Business Profile -->
        <vx-card class="accordin-card mt-4">
          <div class="vx-card__body bsv p-0">
            <vs-collapse accordion>
              <vs-collapse-item open ref="templateCollapse">
                <div slot="header">
                  <div style="width: 40%;" class="flex flex-row">
                    <feather-icon icon="FileTextIcon" svgClasses="w-4 h-4"/>
                    <h3 class="text-left card-title font-normal px-4">
                      Business Profile
                    </h3>
                  </div>
                </div>
                <vs-row>
                  <div>

                      <BusinessProfile
                      :businessProfile="onboardDetail.businessProfile"
                      :userId="onboardDetail.userId"
                      :showEditButton="showEditButton"
                      />
                  </div>
                </vs-row>
              </vs-collapse-item>
            </vs-collapse>
          </div>
        </vx-card>

        <!-- Bank Account -->
        <vx-card class="accordin-card mt-4">
          <div class="vx-card__body bsv p-0">
            <vs-collapse accordion>
              <vs-collapse-item open ref="templateCollapse">
                <div slot="header">
                  <div style="width: 40%;" class="flex flex-row">
                    <feather-icon icon="CodepenIcon" svgClasses="w-4 h-4"/>
                    <h3 class="text-left card-title font-normal px-4">
                      Bank Account
                    </h3>
                  </div>
                </div>
                <vs-row>
                  <div>
                   <BankAccounts
                   :bankAccount="onboardDetail.bankAccount"
                   :userId="onboardDetail.userId"
                   :showEditButton="showEditButton"
                   />
                  </div>
                </vs-row>

              </vs-collapse-item>
            </vs-collapse>
          </div>
        </vx-card>

        <!-- Product Selection -->
        <vx-card class="accordin-card mt-4">
          <div class="vx-card__body bsv p-0">
            <vs-collapse accordion>
              <vs-collapse-item open ref="templateCollapse">
                <div slot="header">
                  <div style="width: 40%;" class="flex flex-row">
                    <feather-icon icon="CodepenIcon" svgClasses="w-4 h-4"/>
                    <h3 class="text-left card-title font-normal px-4">
                      Product Selection
                    </h3>
                  </div>
                </div>

                <div>
                  <ProductSelection

                  :product="onboardDetail.productSelection"
                   :userId="onboardDetail.userId"
                   :showEditButton="showEditButton"
                  ></ProductSelection>
                </div>
              </vs-collapse-item>
            </vs-collapse>
          </div>
        </vx-card>


        <!-- Supporting documents -->
        <vx-card class="accordin-card mt-4">
          <div class="vx-card__body bsv p-0">
            <vs-collapse accordion>
              <vs-collapse-item open ref="templateCollapse">
                <div slot="header">
                  <div style="width: 40%;" class="flex flex-row">
                    <feather-icon icon="CodepenIcon" svgClasses="w-4 h-4"/>
                    <h3 class="text-left card-title font-normal px-4">
                     Supporting documents
                    </h3>
                  </div>
                </div>

                <div>
                  <SupportingDocument
                   :product="onboardDetail.productSelection"
                   :userId="onboardDetail.userId"
                   :showEditButton="showEditButton"
                  ></SupportingDocument>
                </div>
              </vs-collapse-item>
            </vs-collapse>
          </div>
        </vx-card>

        <!-- ID Verification -->
        <vx-card class="accordin-card mt-4">
          <div class="vx-card__body bsv p-0">
            <vs-collapse accordion>
              <vs-collapse-item  ref="templateCollapse">
                <div slot="header">
                  <div style="width: 40%;" class="flex flex-row">
                    <feather-icon icon="CodepenIcon" svgClasses="w-4 h-4"/>
                    <h3 class="text-left card-title font-normal px-4">
                     ID Verification
                    </h3>
                  </div>
                </div>

                <div>

                </div>
              </vs-collapse-item>
            </vs-collapse>
          </div>
        </vx-card>

         <!-- Audit Log -->
        <vx-card class="accordin-card mt-4">
          <div class="vx-card__body bsv p-0">
            <vs-collapse accordion>
              <vs-collapse-item  ref="templateCollapse">
                <div slot="header">
                  <div style="width: 40%;" class="flex flex-row">
                    <feather-icon icon="CodepenIcon" svgClasses="w-4 h-4"/>
                    <h3 class="text-left card-title font-normal px-4">
                     Audit Log
                    </h3>
                  </div>
                </div>

                <div>

                </div>
              </vs-collapse-item>
            </vs-collapse>
          </div>
        </vx-card>






        <div class="mt-10 flex flex-end w-full">

          <vs-button
            flat
            v-round
            class="border-only-btn float-right mr-2"

          >
            Reject
          </vs-button>

             <vs-button
            flat
            v-round
            class="border-only-btn float-right mr-2"
            :disabled="false"
          >
            Approve
          </vs-button>

             <vs-button
            flat
            v-round
            class="border-only-btn float-right"

          >
            Cancel
          </vs-button>

        </div>


    </vs-row>



  </div>
</template>

<script>
import {mapActions} from 'vuex';
import GeneralInformation from './businessInformation/GeneralInformation.vue'
import BeneficiaryOwner from './businessInformation/BeneficiaryOwner.vue'
import AuthorisedSignature from "./businessInformation/AuthorisedSignature.vue"
import PrimaryContactPerson from "./businessInformation/PrimaryContactPerson.vue"
import BusinessProfile from "./businessProfile/BusinessProfile.vue";
import BankAccounts from "./bankAccount/bankAccounts.vue"
import ProductSelection from "./product/ProductSelection.vue"
import SupportingDocument from "./supportingDocument/SupportingDocument.vue"
export default {
  name: "index",
  components: {
      GeneralInformation,
      BeneficiaryOwner,
      AuthorisedSignature,
      PrimaryContactPerson,
      BusinessProfile,
      BankAccounts,
      ProductSelection,
      SupportingDocument
  },
  data() {
    return{
      onboardingId: "",
      onboardDetail:{},
      showEditButton:true
    }
  },

  mounted() {
    this.onboardingId = this.$route.params.onboardingId;
  },

  methods:{
    ...mapActions("onboardMerchant",['fetchOnboardDetailWithId','submitApplication']),

    async getDetail(){
       await this.fetchOnboardDetailWithId("617785f5265b9d210147767b").then((result)=>{
          
          this.onboardDetail = result.data.data;
          this.userId = result.data.data.userId;

          if(result.data.data.status == "Completed"){
            this.showEditButton = false;
          }
       }).catch((err)=>{{}});
    },
    async submitCompleteApplication(){
      const data = {
        _id: this.onboardingId,
      }

      await this.submitApplication(data).then((result)=>{
          
          if(result.data.data){
            this.$router.push({name:"SubmissionApplication"});
          }
       }).catch((err)=>{{}});
    }
  },

  beforeMount(){
      this.getDetail();
  }


}
</script>
